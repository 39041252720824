import React from "react"

import Layout from "../components/Layout"
import EConsent from "../components/EConsent"
import SEO from "../components/seo"

const EConsentPage = ({ location, history }) => (
  <Layout location={location} history={history}>
    <SEO title="E-Consent" />
    <EConsent />
  </Layout>
)

export default EConsentPage
